import React, { ReactNode } from "react";

import Layout from "../components/Layout/Layout";
import { Link } from "../components/Link/Link";
import { LinkType } from "../components/Link/Link.types";

import { Routes } from "../types/routes";

const NotFoundPage = (): ReactNode => (
  <Layout>
    <section className="p-not-found u-wrapper">
      <h2 className="p-not-found__title u-heading-xlarge">404</h2>
      <h3 className="u-heading-large p-not-found__subtitle ">Pagina niet gevonden.</h3>
      <Link
        title="link.tiltle"
        href={Routes.Home}
        type={LinkType.Internal}
        cn="p-not-found__link a-link u-heading-small"
      >
        Terug naar de startpagina
      </Link>
    </section>
  </Layout>
);

export default NotFoundPage;
